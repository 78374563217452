<template>
  <div class="row">
    <ComponentLoader :loading="loading" v-if="loading" />
    <div class="col-md-12 my-4">
      <div class="d-flex justify-content-end">
        <div class="form_contain">
          <el-input
            placeholder="search user"
            class="search_item notranslate"
            v-model="search"
            clearable
            @input="searchUser"
            :size="'large'"
            v-if="showSearchButton">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-input
            placeholder="Search user"
            class="notranslate"
            v-else
            v-model="search"
            clearable
            :size="'large'">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <el-table
        :data="displayData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100vw; overflow-x: auto !important"
        :empty-text="'No Data Available'"
        @selection-change="handleSelectionChange"
        height="55vh"
        min-height="55vh"
        v-loading="tableLoading"
        ref="usersTable">
        <el-table-column type="selection" width="55" v-if="!disallowedUsers">
        </el-table-column>
        <el-table-column
          prop="name"
          label="Name"
          width="200"
          class-name="notranslate"
          v-if="disallowedUsers">
        </el-table-column>
        <el-table-column
          v-else
          prop="name"
          label="Name"
          class-name="notranslate"
          :filters="[
            { text: 'Open Sender ID Active', value: 1 },
            { text: 'Open Sender ID Inactive', value: 0 },
          ]"
          :filter-method="filterTemplate"
          filter-placement="bottom-end"
          width="200">
        </el-table-column>

        <el-table-column
          prop="phone"
          label="Phone"
          width="200"
          class-name="notranslate">
        </el-table-column>
        <el-table-column
          prop="country"
          label="Country"
          class-name="notranslate">
        </el-table-column>
        <el-table-column
          prop="country_code"
          label="Country Code"
          width="200"
          class-name="notranslate">
        </el-table-column>
        <el-table-column
          width="200"
          prop="created_at"
          label="Date"
          v-if="this.$route.path.includes('admin/users/inactive')">
          <template slot-scope="scope">
            <span>
              {{ moment(scope.row.created_at).format("DD MMM YYYY hh:mm A") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          label="SMS Balance"
          v-if="!$route.path.includes('admin/users/inactive')">
          <template slot-scope="scope">{{
            scope.row.sms_balance ? scope.row.sms_balance : 0
          }}</template>
        </el-table-column>
        <el-table-column
          width="200"
          label="Bonus"
          v-if="!$route.path.includes('admin/users/inactive')">
          <template slot-scope="scope">{{
            scope.row.sms_bonus ? scope.row.sms_bonus : 0
          }}</template>
        </el-table-column>
        <el-table-column label="Wallet" width="200">
          <template slot-scope="scope">{{
            scope.row.wallet ? scope.row.wallet : 0
          }}</template>
        </el-table-column>
        <el-table-column
          width="200"
          label="Voice Balance"
          v-if="!$route.path.includes('admin/users/inactive')">
          <template slot-scope="scope">{{
            scope.row.voice_balance ? scope.row.voice_balance : 0
          }}</template>
        </el-table-column>
        <!-- <el-table-column prop="subscription" label="Subscription" v-if="this.$route.path.includes('admin/users/individual') || this.$route.path.includes('admin/users/sme') || this.$route.path.includes('admin/users/corporate')">
                </el-table-column> -->
        <el-table-column
          width="200"
          label="Type"
          v-if="this.$route.path.includes('admin/users/inactive')">
          <template slot-scope="scope">
            <p
              v-if="scope.row.is_active == 0"
              style="color: #f7921c; margin: 0 auto">
              Inactive
            </p>
            <p
              v-if="scope.row.is_active == 3"
              style="color: #ee6463; margin: 0 auto">
              Deactivated
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Action" width="180">
          <template slot-scope="scope">
            <button id="enter__account" @click="openTopUpAccountModal(scope)">
              Top Up Account
            </button>
            <el-dropdown trigger="click">
              <el-button id="__edit">
                <img src="../../../assets/More Square.svg" alt="img" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <span v-if="!checkFradulentAndTaggedAccount">
                  <el-dropdown-item>
                    <button
                      class="__dropdown_btn"
                      @click="impersonateUser(scope)">
                      Enter Account
                    </button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="!disallowedUsers">
                    <button
                      class="__dropdown_btn"
                      @click="toggleUserType(scope)">
                      Toggle Account Type
                    </button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="!disallowedUsers">
                    <button
                      class="__dropdown_btn"
                      @click="toggleOpenSenderID(scope)">
                      Toggle Open Sender ID
                    </button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="!disallowedUsers">
                    <button
                      class="__dropdown_btn"
                      @click="toggleSubscriptionType(scope)">
                      Toggle Subscription Type
                    </button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <button
                      class="__dropdown_btn"
                      @click="handleResetAccount(scope)">
                      Reset Password
                    </button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="!disallowedUsers"
                    ><button
                      class="__dropdown_btn"
                      @click="handleTagAccount(scope)">
                      Tag account
                    </button></el-dropdown-item
                  >
                </span>
                <el-dropdown-item
                  v-if="$route.path !== '/admin/users/inactive'">
                  <button
                    class="__dropdown_btn"
                    @click="handleDeactivateAccount(scope)">
                    Deactivate account
                  </button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <button class="__dropdown_btn" @click="handleDelete(scope)">
                    Delete account
                  </button>
                </el-dropdown-item>
                <span v-if="checkFradulentAndTaggedAccount">
                  <el-dropdown-item>
                    <button class="__dropdown_btn" @click="activate(scope)">
                      Activate
                    </button>
                  </el-dropdown-item>
                </span>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
    <messageModal
      ref="messageModal"
      :payload="dataPayload"
      :from="from"
      @deleteSuccess="deleteSuccess" />
    <ActionModal ref="actionModal" :payload="actionModalPayload" />
    <TopUpAccount
      ref="topupaccount"
      :payload="dataPayload"
      @topUpAccountRefresh="refreshTable"
      @close="dataPayload = {}" />
    <toggleUserType
      ref="toggleUserType"
      :modalPayload="modalPayload"
      @cancelModal="showDialog = false"
      :showDialog="showDialog"
      @closed="showDialog = false"
      @refreshTable="refreshTable" />
    <toggleSubscriptionType
      ref="toggleSubscriptionType"
      :modalPayload="modalPayload"
      @cancelModal="showSubsciptionTypeModal = false"
      :showSubsciptionTypeModal="showSubsciptionTypeModal"
      @closed="showSubsciptionTypeModal = false"
      @refreshTable="refreshTable" />
    <toggleOpenSenderID
      :modalPayload="modalPayload"
      @cancelModal="showOpenSenderDialog = false"
      :showOpenSenderDialog="showOpenSenderDialog"
      @closed="showOpenSenderDialog = false"
      @refreshTable="refreshTable" />
    <transition name="el-fade-in-linear">
      <BulkSelectAction
        v-if="bulkSelectActive"
        @update:visible="cancelBulkSelect"
        :bulkSelectedData="bulkSelectedData"></BulkSelectAction>
    </transition>
  </div>
</template>
<script>
  import $ from "jquery";
  import messageModal from "../../dashboard/messages/messageModal.vue";
  import TopUpAccount from "./topupAccountModal.vue";
  import store from "@/state/store.js";
  import Storage from "../../../services/storage";
  import togglerCorporateStatus from "@/helpers/toggleCorporateStatus.js";
  import toggleUserType from "./toggleUserType.vue";
  import toggleSubscriptionType from "./toggleSubscriptionType.vue";
  import toggleOpenSenderID from "./toggleOpenSenderID.vue";
  import BulkSelectAction from "./BulkSelectAction.vue";
  import _ from "lodash";
  export default {
    name: "corporate-users",
    props: {
      parent: {
        type: Object,
        required: true,
      },
    },
    components: {
      messageModal: messageModal,
      TopUpAccount: TopUpAccount,
      toggleUserType: toggleUserType,
      toggleSubscriptionType: toggleSubscriptionType,
      toggleOpenSenderID: toggleOpenSenderID,
      BulkSelectAction: BulkSelectAction,
    },
    data() {
      return {
        from: "",
        dataPayload: {},
        actionModalPayload: {},
        page: 1,
        pageSize: 50,
        total: 0,
        search: "",
        tabelMainData: [],
        tableLoading: false,
        buttonLoading: false,
        modalPayload: {},
        showDialog: false,
        showSubsciptionTypeModal: false,
        searchPayload: {
          status: "",
          search: "",
        },
        tableSelectUsers: [],
        bulkSelectActive: false,
        bulkSelectedData: [],
        bulkDelete: "",
        bulkDeactivate: "",
        bulkTagAccounts: "",

        showOpenSenderDialog: false,
      };
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
        this.tableLoading = true;
        let categoryHandleChange = {
          category: this.parent.category.category,
          page: val,
        };

        store
          .dispatch(
            this.parent.dispatchName,
            this.parent.category == null ? val : categoryHandleChange
          )
          .then((res) => {
            this.tabelMainData = res.data;
            this.total = res.total;
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      impersonateUser(data) {
        store
          .dispatch("admin/users/impersonateUser", {
            id: data.row.id,
          })
          .then((res) => {
            let userData = {
              name:
                res.individual == null
                  ? res.coporate?.name || "No Name"
                  : `${res.individual?.firstname} ${res.individual?.lastname}` ||
                    "No Name",

              phone: res.phone,
              profile_type: res.individual == null ? 1 : 2,
              token: res.token,
              user_id: res.id,
              email: res.email || "",
              country: res.country_code || "GH",
            };
            if (res.coporate == null) {
              togglerCorporateStatus(false);
            }
            if (res.individual == null) {
              togglerCorporateStatus(true);
            }
            let storage = new Storage();
            localStorage.setItem("userName", userData.name);
            storage.saveAndDelete("currentUser", userData);
            storage.saveAndDelete("token", res.token);
            storage.saveAndDelete("defaultLoginType", "sms");
            window.open("/overview");
          });
      },
      activate(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to activate this account?",
          action: "activate",
          actionMessage: "Yes",
          data: message.row,
          from: this.parent.name,
          index: message.$index,
        };
        this.openActionModal();
      },
      openTopUpAccountModal(data) {
        let element = this.$refs.topupaccount.$el;
        this.dataPayload = {
          data: data.row,
          selectedKey: data.$index,
        };
        $(element).modal("show");
      },
      handleDeactivateAccount(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to deactivate this account?",
          action: "deactiveUser",
          actionMessage: "Yes",
          data: message.row,
          from: this.parent.name,
          index: message.$index,
        };
        this.openActionModal();
      },
      handleTagAccount(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to do this?",
          action: "tagAccount",
          actionMessage: "Yes",
          data: message.row,
          index: message.$index,
          from: this.parent.name,
        };
        this.openActionModal();
      },
      handleResetAccount(message) {
        this.actionModalPayload = {
          message: "Are you sure you want to reset the password?",
          action: "resetPassword",
          actionMessage: "Reset Password",
          data: message.row,
        };
        this.openActionModal();
      },
      openActionModal() {
        let element = this.$refs.actionModal.$el;
        $(element).modal("show");
      },
      handleDelete(data) {
        this.dataPayload = {
          currentModal: "delete",
          selectedKey: data.$index,
          selectedMessage: data.row,
          deleteType: "_deleteUser",
          userDeleteType: "individual",
          from: this.parent.name,
          deleteMessage:
            "Deleting user would permanently delete from all associated  groups.",
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "delete",
        });
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      handleCommand() {},
      deleteSuccess() {
        this.refreshTable();
      },

      handleSelectionChange(val) {
        if (val.length == 0) {
          this.bulkSelectActive = false;
          return;
        }
        this.bulkSelectActive = true;
        this.bulkSelectedData = val;
      },
      cancelBulkSelect($event) {
        this.bulkSelectActive = false;
        this.bulkSelectedData = [];
        this.$refs.usersTable.clearSelection();
        if ($event) {
          this.refreshTable();
        }
      },

      refreshTable() {
        this.redoRefresh();
      },
      searchUser: _.debounce(function () {
        if (this.$route.path == "/admin/users/userSegments/GoldUsers") {
          this.searchPayload.status = 1;
        } else if (
          this.$route.path == "/admin/users/userSegments/SilverUsers"
        ) {
          this.searchPayload.status = 2;
        } else if (
          this.$route.path == "/admin/users/userSegments/BronzeUsers"
        ) {
          this.searchPayload.status = 3;
        }
        if (this.search !== "") {
          this.searchPayload.search = this.search;
          this.tableLoading = true;
          let whichPayload = this.$route.path.includes(
            "/admin/users/userSegments"
          )
            ? this.searchPayload
            : this.search;
          store
            .dispatch(this.parent.searchDispatchName, whichPayload)
            .then((res) => {
              this.tabelMainData = res.data.data;
              this.total = this.tabelMainData.length;
              this.page = 1;
            })
            .finally(() => {
              this.tableLoading = false;
            });
        }
      }, 700),
      testChange(value) {
        this.page = 1;
        if (value == "") {
          this.redoRefresh();
        } else {
          return;
        }
      },
      redoRefresh() {
        this.page = 1;
        this.tableLoading = true;
        store
          .dispatch(
            this.parent.dispatchName,
            this.parent.category == null ? this.page : this.parent.category
          )
          .then((res) => {
            if (
              this.parent.name == "inactive" ||
              this.parent.name == "tagged"
            ) {
              this.total = res.length;
              this.tabelMainData = res;
            } else {
              this.total = res.total;
              this.tabelMainData = res.data;
            }
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      toggleUserType(scope) {
        // Package the data to be sent to the modal
        this.modalPayload = {
          data: scope.row,
          index: scope.$index,
          from: this.parent.name,
        };
        // Show the modal
        this.showDialog = true;
      },
      toggleSubscriptionType(scope) {
        // Package the data to be sent to the modal
        this.modalPayload = {
          data: scope.row,
          index: scope.$index,
          from: this.parent.name,
        };
        // Show the modal
        this.showSubsciptionTypeModal = true;
      },

      toggleOpenSenderID(scope) {
        // Package the data to be sent to the modal
        this.modalPayload = {
          data: scope.row,
          index: scope.$index,
          from: this.parent.name,
        };
        // Show the modal
        this.showOpenSenderDialog = true;
      },
      filterTemplate(value, row) {
        return row.open_sender_is_active === value;
      },
    },
    computed: {
      checkFradulentAndTaggedAccount() {
        let isTaggedOrFradulent = false;
        if (this.$route.path == "/admin/users/fraudulent") {
          isTaggedOrFradulent = true;
        } else if (this.$route.path == "/admin/users/inactive") {
          isTaggedOrFradulent = true;
        }
        return isTaggedOrFradulent;
      },
      loading() {
        return store.getters["admin/users/loading"];
      },
      displayData() {
        if (this.parent.name == "inactive" || this.parent.name == "tagged") {
          return this.tabelMainData.filter((item) => {
            return item.name
              ? item.name.toLowerCase().includes(this.search.toLowerCase()) ||
                  item.phone.toLowerCase().includes(this.search.toLowerCase())
              : item.email.toLowerCase().includes(this.search.toLowerCase()) ||
                  item.phone.toLowerCase().includes(this.search.toLowerCase());
          });
        } else {
          return this.tabelMainData;
        }
      },
      showSearchButton() {
        if (this.parent.name == "inactive" || this.parent.name == "tagged") {
          return false;
        } else {
          return true;
        }
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
      checkAllowedUsers() {
        if (this.disallowedUsers) {
          return "";
        }
        return "filter";
      },
    },
    mounted() {
      this.redoRefresh();

      store.dispatch("admin/reporting/getChartAccounts");
    },
    watch: {
      search: function (val) {
        if (val == "" && this.showSearchButton) {
          this.redoRefresh();
          this.page = 1;
        }
      },
      bulkSelectActive: function (val) {
        if (val == false) {
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  input {
    width: 500px;
  }

  .__dropdown_btn {
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    text-align: left;
  }

  #enter__account {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: #f7921c;
    outline: none;
    border: none;
    background-color: transparent;
  }

  #__edit {
    outline: none;
    border: none;
    background: transparent;
    margin-left: 0.5em;
    width: 10px !important;
    padding: 0px;
  }

  .form_contain {
    display: flex;

    & button {
      margin-left: 1em;
      padding: 1rem;
    }

    & button:disabled {
      background-color: #a59f99;
      color: #fff;
      border-color: #a59f99;
    }
  }
  .bulk_select:disabled {
    background-color: #e4e4e4 !important;
    color: #8e8e8e !important;
    border-color: #e4e4e4;
  }
  .__delete_selected {
    filter: drop-shadow(0 0.2rem 0.75rem #000000cf);
  }

  button:disabled {
    background-color: #d8d6d6 !important;
    border-color: #d8d6d6 !important;
  }
</style>
